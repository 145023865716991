<template>
	<v-layout column fill-height>
		<v-flex scroll-y>
			<WSection :sticky="$vuetify.breakpoint.smAndDown" :title="$t('customer.fiscal.general_infos') + ' :'">
				<v-layout row wrap>
					<v-flex px-1 xs12 sm6 md4 lg3 xl2>
						<v-autocomplete v-model="copyCompany.details.fiscal_statut" :label="$t('customer.fiscal.fiscal_status')" :items="fiscalStatus"></v-autocomplete>
					</v-flex>
					<v-flex px-1 xs12 sm6 md4 lg3 xl2>
						<v-autocomplete v-model="copyCompany.details.fiscal_type" :label="$t('customer.fiscal.fiscal_type')" :items="fiscalTypes"></v-autocomplete>
					</v-flex>
					<v-flex px-1 xs12 sm6 md4 lg3 xl2>
						<v-autocomplete v-model="copyCompany.details.tva_type" :label="$t('customer.fiscal.tva_type')" :items="tvaTypes"></v-autocomplete>
					</v-flex>
					<v-flex px-1 xs12 sm6 md4 lg3 xl2>
						<w-number-input v-model="copyCompany.details.tva_period" :min="1" :max="31" :label="$t('customer.fiscal.tva_period')" />
					</v-flex>
					<v-flex px-1 xs12 sm6 md4 lg3 xl2>
						<v-text-field v-model="copyCompany.details.tva_intra_comm" :label="$t('customer.fiscal.tva_intra_comm')" maxlength="13" />
					</v-flex>
					<v-flex px-1 xs12 sm6 md4 lg3 xl2>
						<v-text-field v-model="copyCompany.details.cga" :label="$t('customer.fiscal.cga')" maxlength="6" />
					</v-flex>
				</v-layout>
				<v-layout px-2 row wrap>
					<v-flex xs6 md4 lg3 xl2>
						<v-switch v-model="copyCompany.details.fiscal_account" color="primary" :label="$t('customer.fiscal.fiscal_account')"></v-switch>
					</v-flex>
					<v-flex xs6 md4 lg3 xl2>
						<v-switch v-model="copyCompany.details.tvts" color="primary" :label="$t('customer.fiscal.tvts')"></v-switch>
					</v-flex>
					<v-flex xs6 md4 lg3 xl2>
						<v-switch v-model="copyCompany.details.ifu" color="primary" :label="$t('customer.fiscal.ifu')"></v-switch>
					</v-flex>
					<v-flex xs6 md4 lg3 xl2>
						<v-switch v-model="copyCompany.details.das2" color="primary" :label="$t('customer.fiscal.das2')"></v-switch>
					</v-flex>
					<v-flex xs6 md4 lg3 xl2>
						<v-switch v-model="copyCompany.details.cfe" color="primary" :label="$t('customer.fiscal.cfe')"></v-switch>
					</v-flex>
					<v-flex xs6 md4 lg3 xl2>
						<v-switch v-model="copyCompany.details.dsi" color="primary" :label="$t('customer.fiscal.dsi')"></v-switch>
					</v-flex>
					<v-flex xs6 md4 lg3 xl2>
						<v-switch v-model="copyCompany.details.irpp" color="primary" :label="$t('customer.fiscal.irpp')"></v-switch>
					</v-flex>
					<v-flex xs6 md4 lg3 xl2>
						<v-switch v-model="copyCompany.details.decloyer" color="primary" :label="$t('customer.fiscal.decloyer')"></v-switch>
					</v-flex>
					<v-flex xs6 md4 lg3 xl2>
						<v-switch v-model="copyCompany.details.cvae" color="primary" :label="$t('customer.fiscal.cvae')"></v-switch>
					</v-flex>
				</v-layout>
				<v-layout align-center justify-end row>
					<v-btn color="primary" :disabled="!isValid" flat round @click="save">{{ $t('actions.save') }}</v-btn>
				</v-layout>
			</WSection>
			<WSection :sticky="$vuetify.breakpoint.smAndDown" :title="$t('customer.fiscal.tax_office_full') + ' :'">
				<v-layout row wrap>
					<v-flex xs12>
						<v-autocomplete
							v-model="copyCompany.details.tax_office_id"
							color="primary"
							:items="taxOffices"
							item-text="name"
							item-value="id"
							:label="$t('customer.fiscal.tax_office')"
							:loading="taxOfficesLoading"
						/>
					</v-flex>
					<v-flex v-if="taxOfficeSelected.id" xs12>
						<v-card flat>
							<v-card-text>
								<v-flex font-weight-bold v-text="taxOfficeSelected.name" />
								<v-flex v-text="taxOfficeSelected.street" />
								<v-flex v-text="taxOfficeSelected.zip + ' ' + taxOfficeSelected.city" />
								<v-flex>
									<a class="primary--text" :href="'mailto:' + taxOfficeSelected.email" v-text="taxOfficeSelected.email" />
								</v-flex>
								<v-flex>
									<a class="primary--text" :href="'tel:' + taxOfficeSelected.phone" v-text="taxOfficeSelected.phone" />
								</v-flex>
							</v-card-text>
						</v-card>
					</v-flex>
					<v-flex v-if="taxOfficeSelected.id" xs12>
						<v-textarea v-model="copyCompany.details.tax_office_comment" auto-grow color="primary" :label="$t('customer.fiscal.tax_office_comment')" />
					</v-flex>
				</v-layout>
				<v-layout align-center justify-end mb-5 pb-4 row>
					<v-flex shrink>
						<v-btn color="primary" flat round @click="save">{{ $t('actions.save') }}</v-btn>
					</v-flex>
				</v-layout>
			</WSection>
		</v-flex>
	</v-layout>
</template>

<script>
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/CustomersManager/CustomersManagerModuleGuard'

export default {
	name: 'CustomerDetailsFiscal',
	mixins: [CustomersManagerModuleGuard],
	props: {
		company: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			copyCompany: {},
			fiscalStatus: ['BIC', 'BNC', 'RF', 'IS', 'BA'],
			fiscalTypes: ['RS', 'RN', 'MICRO', 'AUTOENTREPRENEUR'],
			taxOfficesLoading: false,
			taxOffices: [],
			tvaTypes: ['ACOMPTE', 'FRANCHISE', 'MINI REEL MOIS', 'MINI REEL TRIMESTRE', 'RN', 'RS', 'SANS OBJECT'],
			rules: {
				tva_period: function (tvaPeriod = null) {
					let result = true
					if (tvaPeriod && (tvaPeriod < 1 || tvaPeriod > 31)) {
						result = this.$t('customer.fiscal.errors.tva_period')
					}
					return result
				}
			}
		}
	},
	computed: {
		taxOfficeSelected: function () {
			const taxOfficeId = this.copyCompany.details.tax_office_id
			const taxOffice = this.taxOffices.find(o => o.id == taxOfficeId)
			let result = {}
			if (taxOffice) {
				result = taxOffice
			}
			return result
		},
		isValid: function () {
			return typeof this.rules.tva_period(this.copyCompany.details.tva_period) !== 'string'
		}
	},
	watch: {
		company: {
			deep: true,
			handler: function () {
				this.reset()
			},
			immediate: true
		}
	},
	mounted: function () {
		this.getTaxOffices()
	},
	methods: {
		getTaxOffices: function () {
			this.taxOfficesLoading = true
			this.service
				.getTaxOffices(this.accountingFirmId)
				.then(res => {
					this.taxOffices = res
				})
				.finally(() => {
					this.taxOfficesLoading = false
				})
		},
		reset: function () {
			this.copyCompany = { ...this.company }
			if (!this.copyCompany.details) {
				this.copyCompany.details = {}
			}
		},
		save: function () {
			this.service
				.updateCompanyDetails(this.accountingFirmId, this.company.id, this.copyCompany)
				.then(this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('client_workspace.details.data_updated')))
		}
	}
}
</script>
